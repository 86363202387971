



























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class DateRange extends Vue {
  @Prop() private value!: {
    startDate: string,
    endDate: string
  }
}
